import React, { useState } from 'react';
import { DappUI } from '@elrondnetwork/dapp-core';
import { routeNames } from 'routes';
import Button from '../Button';

const Login = () => {
  const [showLoginMaiar, setShowLoginMaiar] = useState(false);

  const handleOpenMaiar = () => {
    setShowLoginMaiar(true);
  };

  const handleCloseMaiar = () => {
    setShowLoginMaiar(false);
  };

  return (
    <section>
      <div className='container'>
        <div className='row d-flex justify-content-center pt-2'>
          <p className='text-center'>
            To avoid bots ProteoDeFi team will allow only users with Maiar App
            to join the whitelist.
          </p>
        </div>
        <div className='row d-flex justify-content-center pt-2'>
          <p className='text-center'>
            If you don’t have Maiar App yet you can install it
            <a
              href='https://maiar.com'
              target='_blank'
              rel='noreferrer'
              className='text-success'
            >
              &nbsp;here
            </a>
            .
          </p>
        </div>
        <div className='row pt-5  d-flex justify-content-center'>
          <Button
            label='Maiar app login'
            onClick={handleOpenMaiar}
            icon={
              <img
                src='/assets/images/maiar.png'
                className='mr-3'
                style={{ width: '50px' }}
              />
            }
          />
        </div>
      </div>

      {showLoginMaiar && (
        <DappUI.WalletConnectLoginContainer
          callbackRoute={routeNames.home}
          loginButtonText='Maiar App'
          logoutRoute={routeNames.home}
          onClose={handleCloseMaiar}
          redirectAfterLogin={true}
          className='wallect-connect-login-modal'
        />
      )}
    </section>
  );
};

export default Login;

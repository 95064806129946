import React from 'react';
import { Modal } from 'react-bootstrap';

interface MessageType {
  show: boolean;
  children: any;
  onHide: any;
}
const Message = ({ show, children, onHide }: MessageType) => {
  return (
    <Modal show={show} centered size='lg' onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className='text-center w-100'>
          <h3 className='text-danger'>Errors executing transaction</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-center'>{children}</Modal.Body>
    </Modal>
  );
};

export default Message;

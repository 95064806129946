import React from 'react';
import './styles.scss';

interface ButtonType {
  label?: string;
  onClick?: any;
  className?: string;
  background?: string;
  href?: string;
  icon?: any;
}

const Button = ({
  label,
  onClick,
  className,
  background,
  href,
  icon
}: ButtonType) => {
  const _className =
    'button d-flex align-items-center' + (className ? ` ${className}` : '');

  let style = {};
  if (background) {
    style = { backgroundColor: background };
  }

  function handleOnClick(e: React.MouseEvent) {
    if (!!onClick) {
      e.preventDefault();
      onClick();
    }
  }
  return (
    <a
      href={href || '#'}
      className={_className}
      onClick={handleOnClick}
      style={style}
    >
      {icon}
      {label}
    </a>
  );
};

export default Button;

import React from 'react';
import { logout, useGetAccountInfo } from '@elrondnetwork/dapp-core';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar as BsNavbar, NavItem, Nav } from 'react-bootstrap';
import { routeNames } from 'routes';

const Navbar = () => {
  const { address } = useGetAccountInfo();

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault();
    logout(`${window.location.origin}${routeNames.home}`);
  };

  const isLoggedIn = Boolean(address);

  return (
    <BsNavbar className='bg-white border-bottom px-4 py-3'>
      <div className='container-fluid'>
        <NavItem className='d-flex align-items-center mr-0'>
          <a href='https://proteodefi.com/'>
            <img
              className='navbar-brand'
              src='assets/images/proteo-logo.png'
              alt='Proteo'
              loading='lazy'
              data-src='assets/images/proteo-logo.png'
            ></img>
          </a>
        </NavItem>

        <Nav className='ml-auto'>
          {isLoggedIn && (
            <NavItem>
              <span className='text-success'>{address}</span>
              <a href='/#' className='text-muted ml-2' onClick={handleLogout}>
                Close <FontAwesomeIcon icon={faSignOutAlt} />
              </a>
            </NavItem>
          )}
        </Nav>
      </div>
    </BsNavbar>
  );
};

export default Navbar;

import React from 'react';

const Footer = () => {
  return (
    <footer className='pt-1 pb-5'>
      <div className='container'>
        <div className='border-top border-secondary row pt-3'>
          <div className='col-lg-6 col-md-12'>
            <h3 className='text-left'>
              Copyright © 2020-2025 - Developed by{' '}
              <a
                href='https://www.moonlorian.com/'
                className='text-success'
                rel='noreferrer'
                target='_blank'
              >
                Moonlorian
              </a>
            </h3>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';

interface ProgressType {
  show: boolean;
  children: any;
}
const Progress = ({ show, children }: ProgressType) => {
  return (
    <Modal show={show} centered size='lg'>
      <Modal.Body className='text-center'>
        <Spinner animation='grow' size='sm' />
        {children}
      </Modal.Body>
    </Modal>
  );
};

export default Progress;

import React, { useState, useEffect } from 'react';
import {
  useGetAccountInfo,
  useGetNetworkConfig
} from '@elrondnetwork/dapp-core';
import {
  Address,
  AddressValue,
  ContractFunction,
  ProxyProvider,
  Query,
  TransactionHash,
  TransactionWatcher
} from '@elrondnetwork/erdjs';
import { addToWhiteList } from 'cli/whitelistAPI';
import Button from 'components/Button';
import Login from 'components/Login';
import Message from 'components/Message';
import Progress from 'components/Progress';
import { contractAddress } from 'config';

const Home = () => {
  const { address } = useGetAccountInfo();
  const { network } = useGetNetworkConfig();
  const isLoggedIn = Boolean(address);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [transactionHash, setTransactionHash] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  function fetchAddressStatus() {
    const query = new Query({
      address: new Address(contractAddress),
      func: new ContractFunction('in_whitelist'),
      args: [new AddressValue(new Address(address))]
    });
    const proxy = new ProxyProvider(network.apiAddress);
    proxy
      .queryContract(query)
      .then(({ returnData }) => {
        setIsWhitelisted(returnData[0] !== '');
      })
      .catch((err) => {
        console.error('Unable to call VM query', err);
      });
  }
  useEffect(() => {
    if (address) {
      fetchAddressStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, transactionHash]);

  const onClickAddAddress = () => {
    setShowProgress(true);
    addToWhiteList(address)
      .then(async (hash) => {
        console.log(hash);
        const proxy = new ProxyProvider(network.apiAddress);
        const watcher = new TransactionWatcher(
          new TransactionHash(hash),
          proxy
        );
        await watcher.awaitStatus(
          (status) => status.isExecuted(),
          (status) => {
            if (status.isExecuted()) {
              setShowProgress(false);
              setTransactionHash(hash);
            }
          }
        );
      })
      .catch((err) => {
        console.error('Unable to add the address', err);
        setShowProgress(false);
        setShowError(true);
      });
  };
  return (
    <>
      <Progress show={showProgress}>
        <p className='pt-3'>Adding the following address to the whitelist...</p>
        <span className='text-success'>{address}</span>
      </Progress>
      <Message
        show={showError}
        onHide={() => {
          setShowError(false);
        }}
      >
        <p>There was an error adding the following address to the whitelist</p>
        <span className='text-success'>{address}</span>
      </Message>
      <section className='waves-backg'>
        <div className='container '>
          <div className='row'>
            <h3 className='mbr-section-title align-center w-100'>
              Whitelist of PROTEO
            </h3>
          </div>
        </div>
      </section>

      {!isLoggedIn && <Login />}

      {isWhitelisted && (
        <section>
          <div className='container'>
            <div className='row d-flex justify-content-center pt-2'>
              <p className='text-center'>
                Your wallet is included in the whitelist of PROTEO.
              </p>
            </div>
          </div>
        </section>
      )}
      {isLoggedIn && !isWhitelisted && (
        <section>
          <div className='container'>
            <div className='row d-flex justify-content-center pt-2'>
              <p className='text-center'>
                If you are not whitelisted follow us in our social accounts and
                stay tuned, we will open again registration at some point.
              </p>
            </div>
            <div className='row d-flex justify-content-center pt-2'>
              <p className='text-center'>
                <a
                  href='https://twitter.com/ProteoDefi'
                  className='text-success'
                  rel='noreferrer'
                  target='_blank'
                >
                  Twitter
                </a>{' '}
                -{' '}
                <a
                  href='https://t.me/proteodefi'
                  className='text-success'
                  rel='noreferrer'
                  target='_blank'
                >
                  Telegram
                </a>
              </p>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Home;
